/** @jsxImportSource @emotion/react */
import { graphql } from "gatsby";
import React from "react";
import SEO from "../components/old/seo";
import Footer from "../components/shared/Footer/Footer";
import MissingContent from "../components/shared/MissingContent/MissingContent";
import Blog from "../features/blog";
import { l_blog } from "../features/blog/locales";
import { ContentfulArticle } from "../templates/Article/Article";

const BlogPage = (props: {
  data: { allContentfulArticle: { edges: { node: ContentfulArticle }[] } };
}) => {
  const articles = props.data.allContentfulArticle.edges.map((e) => e.node);

  return articles && articles.length > 0 ? (
    <React.Fragment>
      <SEO title="Blog" description={l_blog("seo.description")} />
      <Blog articles={articles} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SEO noIndex={true} />
      <MissingContent />
      <Footer />
    </React.Fragment>
  );
};

export const query = graphql`
  query BlogQuery($localeRegex: String) {
    allContentfulArticle(
      filter: { node_locale: { regex: $localeRegex }, isVisible: { eq: true } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      edges {
        node {
          id
          slug
          title
          subtitle
          isVisible
          updatedAt
          publishedAt
          category {
            title
            fullname
          }
          foregroundImage {
            fluid(maxWidth: 1000, quality: 95) {
              ...GatsbyContentfulFluid_withWebp
              ...GatsbyContentfulFluid
            }
          }
          author {
            name
            surname
            role
          }
        }
      }
    }
  }
`;

export default BlogPage;
