/** @jsxImportSource @emotion/react */
import { Tab } from "@headlessui/react";
import { Box, Stack } from "@mui/material";
import React, { Fragment } from "react";
import BlogTypography from "../../theme/BlogTypography";

const ArticleChip = ({ title }: { title: string }) => {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <Box
          sx={{
            width: "fit-content",
            border: "none",
            cursor: "pointer",
            outline: "none",
            padding: "4px 12px",
            borderRadius: "16px",
            ":hover": {
              backgroundColor: "rgba(255, 122, 0, 0.06)",
            },
          }}
        >
          <BlogTypography
            variant="overtitle2"
            sx={{
              color: "black",
              fontWeight: selected ? 700 : 400,
              letterSpacing: selected ? 0.45 : 0.75,
            }}
          >
            {title}
          </BlogTypography>
        </Box>
      )}
    </Tab>
  );
};

const ArticleChips = ({
  onChange,
}: {
  onChange: (category: string) => void;
}) => {
  const categories = ["all", "focus", "tips", "insights"];
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "8px",
        marginBottom: { md: "120px", xs: "50px" },
      }}
    >
      <Tab.Group
        onChange={(ind: number) => {
          onChange(categories[ind]);
        }}
      >
        {categories.map((category) => (
          <ArticleChip title={category} key={category} />
        ))}
      </Tab.Group>
    </Stack>
  );
};

export default ArticleChips;
